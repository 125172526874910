<template>
  <common-dialog
    ref="dialog"
    :trigger="clear"
    notif="Your local database has been complety cleared"
    title="Database clear"
    message="You are about to delete all your local data. Do you confirm ?"
    cancel="Cancel"
    ok="Clear"
    message-type="warning"
    width="350"
  >
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'DriveClearDialog',
  methods: {
    ...mapActions('drive', ['clear']),

    open: function () {
      this.error = null
      this.$refs.dialog.open()
    }
  },
  components: {
    CommonDialog
  }
}
</script>
