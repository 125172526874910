<template>
  <v-list class="pa-0" dense>
    <button-menu-item
      prepend-icon="pencil-outline"
      label="Edit"
      :to="editLink"
      v-if="editLink"
    />

    <button-menu-item
      prepend-icon="rename-box"
      label="Rename"
      v-if="!isCurrentAndRoot"
    >
      <rename-dialog :item="item" />
    </button-menu-item>

    <button-menu-item
      prepend-icon="tag-text-outline"
      label="Metadatas"
      v-if="!isCurrent && !isFolder"
    >
      <meta-dialog :item="item" />
    </button-menu-item>

    <button-menu-item
      prepend-icon="delete-outline"
      label="Delete"
      v-if="!isCurrent"
    >
      <delete-dialog :item="item" />
    </button-menu-item>

    <!-- NEW FILE AND FOLDER -->

    <v-divider v-if="isCurrent" />

    <button-menu-item
      prepend-icon="file-plus-outline"
      label="New score"
      v-if="isCurrent"
    >
      <create-dialog />
    </button-menu-item>

    <button-menu-item
      prepend-icon="file-import-outline"
      label="Import (ChordPro)"
      v-if="isCurrent"
    >
      <chordpro-dialog />
    </button-menu-item>

    <button-menu-item
      prepend-icon="folder-plus-outline"
      label="New folder"
      v-if="isCurrent"
    >
      <create-dialog folder />
    </button-menu-item>

    <v-divider v-if="isCurrentAndRoot && isLocal" />

    <!-- DATABASE OPERATIONS -->

    <button-menu-item
      v-if="isCurrentAndRoot && isLocal"
      prepend-icon="database-export-outline"
      label="Export database"
    >
      <drive-export-dialog />
    </button-menu-item>

    <button-menu-item
      v-if="isCurrentAndRoot && isLocal"
      prepend-icon="database-import-outline"
      label="Import database"
    >
      <drive-import-dialog />
    </button-menu-item>

    <button-menu-item
      v-if="isCurrentAndRoot && isLocal"
      prepend-icon="database-remove-outline"
      label="Clear database"
    >
      <drive-clear-dialog />
    </button-menu-item>

    <!-- VIEW MENU -->

    <v-divider v-if="isCurrent" />

    <sub-menu-item v-if="isCurrent" icon="sort" :label="sortLabel">
      <select-menu-item
        :items="sortItems"
        :value="settings.drive.sort"
        @change="sortChanged($event)"
      ></select-menu-item>
    </sub-menu-item>
  </v-list>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

import DriveExportDialog from '../dialog/DriveExportDialog.vue'
import DriveImportDialog from '../dialog/DriveImportDialog.vue'
import DriveClearDialog from '../dialog/DriveClearDialog.vue'
import RenameDialog from '../dialog/RenameDialog.vue'
import DeleteDialog from '../dialog/DeleteDialog.vue'
import CreateDialog from '../dialog/CreateDialog.vue'
import ChordproDialog from '../dialog/ChordproDialog.vue'
import MetaDialog from '../dialog/MetaDialog.vue'

import SelectMenuItem from '../menu-item/SelectMenuItem'
import ButtonMenuItem from '../menu-item/ButtonMenuItem'
import SubMenuItem from '../menu-item/SubMenuItem'

export default {
  name: 'DriveMenu',
  components: {
    SelectMenuItem,
    ButtonMenuItem,
    SubMenuItem,

    DriveExportDialog,
    DriveImportDialog,
    DriveClearDialog,
    RenameDialog,
    DeleteDialog,
    CreateDialog,
    ChordproDialog,
    MetaDialog
  },
  props: {
    item: Object
  },
  data: () => ({
    sortItems: [
      {
        label: 'Sort by name',
        value: { on: 'name' },
        icon: 'sort-alphabetical-ascending'
      },
      {
        label: 'Sort by date',
        value: { on: 'date' },
        icon: 'sort-clock-ascending-outline'
      }
    ]
  }),
  computed: {
    ...mapGetters('drive', ['current']),
    ...mapGetters('app', ['settings']),

    isCurrent() {
      return this.item && this.current.id === this.item.id
    },
    isFolder() {
      return this.item?.isFolder
    },
    isCurrentAndRoot() {
      return this.isCurrent && this.current.isLocalRoot
    },
    isLocal() {
      return this.$route.params.driveId === 'local'
    },
    editLink() {
      return this.item?.isFile && `/score/editor/${this.item.path}`
    },
    sortLabel() {
      return this.sortItems.find(
        (e) => e.value.on === this.settings.drive.sort.on
      )?.label
    }
  },
  methods: {
    ...mapActions('app', ['setSettings']),
    ...mapActions('drive', ['sort', 'del']),
    ...mapMutations('drive', ['setMenu']),

    sortChanged(event) {
      this.sort(event)
      this.$emit('subclick')
    }
  }
}
</script>
<style scoped></style>
