<template>
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    title="Database export"
    message="That will export your local database to a json file"
    cancel="Cancel"
    ok="Export"
    :disabled="!valid"
  >
    <v-form ref="form" lazy-validation v-model="valid">
      <v-text-field
        label="Filename"
        required
        v-model="fileName"
        :rules="fileNameRules"
      >
      </v-text-field>
    </v-form>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'DriveExportDialog',
  data: () => ({
    fileName: 'SongJelly Database',
    fileNameRules: [(v) => !!v || 'File name is required'],
    valid: false
  }),

  methods: {
    ...mapActions('drive', ['toJson']),

    open: function() {
      this.$refs.dialog.open()
    },

    proceed() {
      return this.toJson(2).then((json) => {
        var a = document.createElement('a')
        var file = new Blob([json], { type: 'text/plain' })
        a.href = URL.createObjectURL(file)
        a.download = this.fileName + '.json'
        a.click()
      })
    }
  },
  components: {
    CommonDialog
  }
}
</script>
