<template>
  <common-dialog
    class="dialog"
    ref="dialog"
    :trigger="proceed"
    :args="file"
    title="Database import"
    message="Please, choose a file to import to your local database"
    cancel="Cancel"
    ok="Import"
    notif="Your database has been updated"
  >
    <v-file-input
      truncate-length="32"
      accept=".json"
      label="Json file"
      v-model="file"
    ></v-file-input>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'DriveImportDialog',
  data: () => ({
    file: null
  }),
  methods: {
    ...mapActions('drive', ['fromJson']),

    open: function() {
      this.error = null
      this.$refs.dialog.open()
    },
    proceed(file) {
      const fromJson = this.fromJson
      return new Promise((resolve, reject) => {
        if (!file) {
          reject({ name: 'FileError', message: 'File required' })
        }
        if (file.type != 'application/json') {
          reject('Wrong format')
        }
        const reader = new FileReader()
        reader.onload = (event) => {
          resolve(event.target.result)
        }
        reader.onerror = () => {
          reject({ message: reader.error.message })
        }
        reader.readAsText(file)
      }).then((json) => {
        return fromJson(json)
      })
    }
  },
  components: {
    CommonDialog
  }
}
</script>
<style scoped>
/*.v-input .v-text-field .v-label {
  display: none !important;
}*/
</style>
