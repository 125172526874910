<template>
  <common-dialog
    class="dialog"
    ref="dialog"
    :trigger="addFromChordPro"
    :args="{ name, raw }"
    title="Chord Pro import"
    message="Tips : Take the time to remove all extra lines which are not defined in the chord pro format (comments, diagram, tabs etc.)"
    cancel="Cancel"
    ok="Import"
    :fullscreen="true"
  >
    <v-text-field
      label="Name of the score"
      v-model="name"
      placeholder=" "
      persistent-placeholder
      hide-details
    />

    <v-textarea
      height="100%"
      no-resize
      hide-details
      label="ChordPro data"
      placeholder=" "
      persistent-placeholder
      v-model="raw"
    />
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'ChordproDialog',
  data: () => ({
    raw: '',
    name: 'New score (from chord pro)'
  }),
  methods: {
    ...mapActions('drive', ['addFromChordPro']),

    open() {
      this.error = null
      this.$refs.dialog.open()
    }
  },
  components: {
    CommonDialog
  }
}
</script>
<style scoped>
/*.v-input .v-text-field .v-label {
  display: none !important;
}*/
.v-text-field {
  flex: none;
}
.v-textarea {
  margin-top: var(--app-gutter-md);

  flex: 1;
}
.v-textarea,
.v-textarea /deep/ .v-input__control,
.v-textarea /deep/ .v-input__slot {
  align-items: stretch;
}

.v-textarea /deep/ textarea {
  font-family: monospace;
  font-size: medium;
  line-height: 16px;
  margin: 8px 0;
}
</style>
